import Colors from '@root/brand/src/utils/colors';
import MylesColors from '@root/trymyles.com/src/utils/myles-colors.js';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import { StyleSheet } from '@root/core/src/utils/styles';

export default function HideIcon({ onClick }) {
  return (
    <a
      css={styles.hide}
      data-testid={'hideIcon'}
      href={'#'}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      title={'Hide Overlay'}
    >
      <svg
        viewBox={'0 0 30 30'}
        xmlns={'http://www.w3.org/2000/svg'}
        xmlnsXlink={'http://www.w2.org/1999/xlink'}
      >
        <title id={'hideOverlay'}>X</title>
        <path
          d={'M 20.9386 19.9705 C 21.2045 20.2364 21.2045 20.6727 20.9386 20.9386 C 20.8023 21.0682 20.6318 21.1364 20.4545 21.1364 C 20.2773 21.1364 20.1068 21.0682 19.9705 20.9386 L 15 15.9682 L 10.0295 20.9386 C 9.89318 21.0682 9.72273 21.1364 9.54545 21.1364 C 9.36818 21.1364 9.19773 21.0682 9.06136 20.9386 C 8.79545 20.6727 8.79545 20.2364 9.06136 19.9705 L 14.0318 15 L 9.06136 10.0295 C 8.79545 9.76364 8.79545 9.32727 9.06136 9.06136 C 9.32727 8.79545 9.76364 8.79545 10.0295 9.06136 L 15 14.0318 L 19.9705 9.06136 C 20.2364 8.79545 20.6727 8.79545 20.9386 9.06136 C 21.2045 9.32727 21.2045 9.76364 20.9386 10.0295 L 15.9682 15 L 20.9386 19.9705 Z'}
        />
      </svg>
    </a>
  );
}

HideIcon.propTypes = {
  onClick: PropTypes.func.isRequired,
};

const styles = StyleSheet.create({
  hide: {
    top: 10,
    right: 10,
    height: 35,
    lineHeight: '40px',
    position: 'absolute',
    width: 35,
    textAlign: 'center',
    ' :hover': {
      ' svg': {
        fill: MylesColors.mylesGreen,
        stroke: MylesColors.mylesGreen,
        textShadow: 'none',
      },
    },
    ' svg': {
      display: 'flex',
      verticalAlign: 'middle',
      fill: Colors.gray50(),
      stroke: Colors.gray50(),
      textSizeAdjust: '100%',
      transitioDelay: '0s',
      transitionDuration: '0.15s',
      transitionProperty: 'all',
      transitionTimingFunction: 'cubic-bezier(0.37, 0, 0.28, 1)',
      ' path': {
        height: 18,
        width: 'auto',
        fontSize: '18px',
      },
    },
  },
});
