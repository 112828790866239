import Colors from '@root/brand/src/utils/colors';
import MylesColors from '@root/trymyles.com/src/utils/myles-colors.js';
import MylesLogo from '@root/trymyles.com/src/components/myles-logo';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import { StyleSheet } from '@root/core/src/utils/styles';
import { bold, regular } from '@root/brand/src/assets/fonts/root-sans/emotion';

export default function GoodbyeRoadside({ toggleModal }) {
  const handleClick = (e) => {
    e.preventDefault();
    toggleModal(e);
  };

  return (
    <div css={styles.mainGoodbye}>
      <div
        className={'logo'}
      >
        <a
          aria-label={'myles logo icon'}
          className={'-desktop-link-text'}
          data-conversion-id={'header image'}
          onClick={handleClick}
        >
          <MylesLogo className={'-desktop-link-button'} />
        </a>
      </div>

      <div className={'goodbyeContent'}>
        <div className={'topSection'}>
          <div className={'contentWithHeader'}>
            <h2 className={'mainHeader'}>Myles Roadside is saying goodbye
            </h2>
            <p className={'listParagraph'}>Starting March 31, 2020, Myles will no longer be accepting or fulfilling roadside assistance requests. While we understand how disappointing this news can be, we want to thank you for being loyal Myles users. Throughout Myles’s tenure, we were able to help 1,730 Myles customers get free roadside service. </p>
            <br />
            <p className={'listParagraph'}>Up until March 31, 2020, you can still: </p>
            <br />
            <ul className={'ul'}>
              <li>
                <p className={'listParagraph'}>Earn or lose 10 points a day</p>
              </li>
              <li>
                <p className={'listParagraph'}>Request free roadside assistance so long as you have 500 points in your account</p>
              </li>
              <li>
                <p className={'listParagraph'}>Request the same types of roadside assistance Myles has always offered such as tows, winch out, battery jump, spare tire installation, fuel delivery, and lockout services</p>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className={'bottomSection'}>
        <div className={'bottomSectionContent'}>
          <div className={'contentWithHeader'}>
            <h2 className={'secondaryHeader'}>What’s next for Myles? </h2>
            <p className={'listParagraph'}>After March 31, 2020, Myles will be converted to a mileage tracking app. Automatic mileage tracking with the Myles app will be useful for business travelers, independent contractors, or anyone who wants more insight into the distance they travel with no extra effort.</p>
          </div>

          <div className={'contentWithHeader'}>
            <h2 className={'secondaryHeader'}>Interested in getting roadside after March 31st?</h2>
            <p className={'listParagraph'}>While roadside support will no longer be available in Myles, Root Insurance includes roadside assistance with every policy. We recommend checking out Root at <a href={'https://www.joinroot.com/'}>https://www.joinroot.com</a> if you’re interested.</p>
          </div>

          <div className={'contentWithHeader'}>
            <h2 className={'secondaryHeader'}>Have more questions?</h2>
            <p className={'listParagraph'}>Feel free to contact us with any questions or concerns at <a href={'mailto:trymyles@joinroot.com'}>trymyles@joinroot.com</a> </p>
          </div>
        </div>
      </div>
    </div>
  );
}

GoodbyeRoadside.propTypes = {
  toggleModal: PropTypes.func.isRequired,
};

const styles = StyleSheet.create({
  mainGoodbye: {
    margin: 'auto',
    ' .logo': {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: '3vw',
      cursor: 'pointer',
    },
    ' .-desktop-link-text svg': {
      fill: Colors.black(),
      stroke: Colors.black(),
      transition: 'all 250ms cubic-bezier(0.37, 0, 0.28, 1)',
      ' :hover': {
        fill: MylesColors.mylesGreen(),
        stroke: MylesColors.mylesGreen(),
      },
    },
    '.topSection': {
      paddingBottom: 30,
    },
    '.bottomSection': {
      padding: 30,
      backgroundColor: Colors.white(),
      marginBottom: 110,
    },
    '.bottomSectionContent': {
      paddingTop: '30px',
      maxWidth: 840,
      margin: 'auto',
    },
    ' .mainHeader': {
      ...bold(),
      marginBottom: '20px',
    },
    ' .secondaryHeader': {
      ...bold(),
      marginBottom: '20px',
    },
    '.contentWithHeader': {
      marginBottom: '30px',
    },
    ' ul': {
      listStyleType: 'disc',
      margin: '0 0 0 40px',
    },
    ' .listParagraph': {
      ...regular(),
      color: MylesColors.mylesGray(),
      fontSize: 20,
      lineHeight: '27px',
    },
    ['@media (max-width: 670px)']: {
      '.topSection': {
        padding: '30px',
        paddingBottom: '10px',
      },
      '.bottomSection': {
        padding: '30px',
        paddingTop: '10px',
      },
      ' .listHeader': {
        fontSize: 18,
        width: '100%',
      },
      ' .listParagraph': {
        fontSize: 16,
        lineHeight: '22.4px',
      },
    },
    ['@media (min-width: 828px)']: {
      ' .goodbyeContent': {
        width: 840,
        margin: 'auto',
        fontSize: 16,
      },
    },
    ['@media (min-width: 1028px)']: {
      ' .mainHeader': {
        fontSize: '48px',
      },
    },
  },
});
