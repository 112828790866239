import AppModal from '@root/trymyles.com/src/components/app-modal';
import Colors from '@root/brand/src/utils/colors';
import GoodbyeRoadside from '@root/trymyles.com/src/components/goodbye-roadside';
import React, { useEffect, useState } from '@root/vendor/react';
import Seo from '@root/trymyles.com/src/components/seo';
import normalizeStyles from '@root/joinroot.com/src/components/normalize.css';
import { StyleSheet } from '@root/core/src/utils/styles';
import { injectGlobal } from '@root/vendor/emotion';

function renderModal(shouldRender, toggleModal) {
  return shouldRender ? <AppModal toggleModal={toggleModal} /> : null;
}

export default function IndexPage() {
  const [modalDisplay, setModalDisplay] = useState(false);

  useEffect(() => {
    document.body.style.height = '100vh';
    document.body.style.overflowY = 'scroll';
    document.documentElement.style.overflow = 'hidden';
    return () => {
      document.body.style.height = null;
      document.body.style.overflowY = null;
      document.documentElement.style.overflow = null;
    };
  }, []);

  const hideOrDisplayModal = () => {
    setModalDisplay(!modalDisplay);
  };

  return (
    <div css={styles.main}>
      <Seo
        author={'Root Insurance Co.'}
        description={'The Myles roadside assistance app lets you earn free roadside service, just for driving. With a few taps, you can request the service you need and receive driver updates, so you know when help is on the way. And you can get roadside service as soon as you download the app - no membership required.'}
        title={'Earn free roadside assistance with the Myles app'}
      />
      <GoodbyeRoadside toggleModal={hideOrDisplayModal} />
      {renderModal(modalDisplay, hideOrDisplayModal)}
    </div>
  );
}

const styles = StyleSheet.create({
  main: {
    marginTop: 0,
    padding: 0,
    width: '100%',
    backgroundColor: Colors.nearWhite(),
    ' h1::selection, h2::selection, h3::selection, p::selection, img::selection, em::selection, span::selection': {
      backgroundColor: 'rgb(4, 220, 90)',
    },
  },
});

injectGlobal(normalizeStyles);

