import Colors from '@root/brand/src/utils/colors';
import HideIcon from '@root/trymyles.com/src/components/hide-icon';
import PropTypes from '@root/vendor/prop-types';
import appstore from '@root/core/src/assets/icons/app-store-badge.svg';
import playstore from '@root/core/src/assets/icons/google-play-store-badge.svg';
import { StyleSheet, Theme } from '@root/core/src/utils/styles';
import { semiBold } from '@root/brand/src/assets/fonts/root-sans/emotion';

export default function AppModal({ toggleModal }) {
  return (
    <div
      aria-label={'background'}
      className={'background'}
      css={styles.appModal}
      onClick={toggleModal}
    >
      <div
        className={'mainDiv'}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div
          aria-label={'modal'}
          className={'content'}
        >
          <h3>Get the Myles app.</h3>
          <div id={'options'}>
            <a
              href={'https://app.appsflyer.com/id1457975587'}
              rel={'noopener noreferrer'}
              target={'_blank'}
              title={'Myles on the App Store'}
            >
              <img
                alt={'App store badge'}
                src={appstore}
              />
            </a>
            <a
              href={'https://app.appsflyer.com/com.joinroot.myles'}
              rel={'noopener noreferrer'}
              target={'_blank'}
              title={'Myles on the Play Store'}
            >
              <img
                alt={'Play store badge'}
                src={playstore}
              />
            </a>
          </div>
          <HideIcon
            onClick={toggleModal}
          />
        </div>
      </div>
    </div>

  );
}

AppModal.propTypes = {
  toggleModal: PropTypes.func.isRequired,
};

const styles = StyleSheet.create({
  appModal: {
    position: 'fixed',
    display: 'flex',
    overflowY: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 20,
    backgroundColor: Colors.DEPRECATED_shadow(0.5),
    ' .mainDiv': {
      height: '100%',
      width: '100%',
      padding: '5vw',
      backgroundColor: Colors.white(),
    },
    ' .content': {
      ...semiBold(),
      width: '100%',
    },
    ' h3': {
      textAlign: 'center',
    },
    ' #options': {
      fontSize: 16,
      marginTop: '40px',
      display: 'flex',
      justifyContent: 'center',
    },
    ' img': {
      height: 49,
      verticalAlign: 'middle',
      width: 145,
      lineHeight: '22.4px',
    },
    ['@media (min-width: 828px)']: {
      ' .background': {
        backgroundColor: Colors.DEPRECATED_shadow(0.5),
        height: 1000,
        width: 1000,
      },
      ' .mainDiv': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 11,
        height: 'auto',
        width: '500px',
      },
      ' .content': {
        backgroundColor: Colors.nearWhite(),
        ...Theme.roundedCorners(),
        border: '2px solid rgb(4, 220, 90)',
        borderImage: 'none 100% 1 0px stretch',
        boxShadow: 'rgba(0, 0, 0, 0.35) 0px 25px 75px -15px',
        display: 'block',
        height: 'auto',
        opacity: 1,
        padding: '60px',
        position: 'absolute',
        textSizeAdjust: '100%',
        transitionDelay: '0.1s',
        transitionDuration: '0.3s',
        transitionProperty: 'all',
        transitionTimingFunction: 'cubic-bezier(0.37, 0, 0.28, 1)',
        width: 500,
        ' h3': {
          color: Colors.black(),
          fontSize: '1.75em',
          fontWeight: 700,
          lineHeight: '1.75em',
          margin: 0,
          textAlign: 'center',
          marginBlock: 0,
          marginInline: 0,
        },
      },
    },
  },
});
